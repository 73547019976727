<template>
  <div class="mb-4">
    <FAQ v-for="(item, i) in items" :key="i">
      <template #question>{{ item.question }}</template>
      <template #answer>
        <prismic-rich-text :field="item.answer" />
      </template>
    </FAQ>
  </div>
</template>

<script>
import FAQ from "./FrequentlyAskedQuestion"

export default {
  name: "FAQs",
  components: {
    FAQ
  },
  props: ["items"]
}
</script>
