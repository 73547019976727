/**
 * @file slugifyMixin.js
 * @author Andy Nelson
 *
 * Mixin to create friendly formatted dates
 */

/** NPM Imports */
import { format } from "date-fns"

/**
 * Module to convert strings into slugs
 * @module
 */
export const dateMixin = {
  methods: {
    /**
     * Converts a Javscript date to a frinedly date
     * @param {Date} aDate - Date to be converted
     *
     * @returns {String} Date formatted as a string
     */
    formattedDate: function(aDate) {
      return format(new Date(aDate), "MMM dd, yyyy")
    }
  }
}
