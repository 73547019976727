<template>
  <div>
    <h2 class="text-h3 primary--text mb-4">
      {{ title }}
    </h2>
    <div>
      <prismic-rich-text :field="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: "SimpleTextSection",
  props: ["title", "content"]
}
</script>
