<template>
  <v-card max-width="400" class="mx-auto">
    <v-list-item>
      <v-list-item-avatar size="130">
        <v-img :src="avatar"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title
          class="headline font-weight-bold"
          v-text="name"
        ></v-list-item-title>
        <div>
          <prismic-rich-text :field="description" />
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  name: "Profile",
  props: {
    avatar: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    description: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped lang="scss"></style>
