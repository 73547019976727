<template>
  <div>
    <div class="shrink">
      <div
        class="d-flex align-center"
        style="cursor:pointer;"
        @click="expand = !expand"
      >
        <div>
          <v-icon v-if="expand" class="icon">$angleDown</v-icon>
          <v-icon v-else class="icon">$caretRight</v-icon>
          <span class="ml-4">
            <slot name="question"></slot>
          </span>
        </div>
      </div>
    </div>
    <v-expand-transition>
      <div v-show="expand" class="answer ma-6 pl-4">
        <slot name="answer"></slot>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: "FAQ",
  data() {
    return {
      expand: false
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/variables";

.icon {
  color: $rw-color-blue;
}

.answer {
  border-left: 1px solid gray;
  padding-left: 6px;
}
</style>
