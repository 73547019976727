<template>
  <prismic-rich-text :field="content"></prismic-rich-text>
</template>

<script>
export default {
  name: "FreeText",
  props: ["content"]
}
</script>
