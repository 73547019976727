<template>
  <h1 class="text-h1 primary--text mb-4">
    {{ title }}
  </h1>
</template>

<script>
export default {
  name: "PageTitle",
  props: ["title"]
}
</script>
