<template>
  <div>
    <v-fade-transition mode="out-in">
      <v-img :aspect-ratio="16 / 9" :src="backgroundSrc" max-height="400">
        <v-row
          align="center"
          justify="center"
          class="lightbox white--text pa-2 fill-height text-center"
        >
          <v-col>
            <div
              class="mx-auto"
              style="min-width:300px;max-width:700px;font-size:2rem;"
            >
              {{ imageIntroText }}
            </div>
          </v-col>
        </v-row>
      </v-img>
    </v-fade-transition>

    <v-container class="pt-6">
      <SliceResolver :slices="slices"></SliceResolver>
    </v-container>
  </div>
</template>

<script>
import SliceResolver from "./../../components/SliceResolver"

export default {
  name: "PrelaunchContent",
  components: {
    SliceResolver
  },
  data() {
    return {
      backgroundSrc: "",
      imageIntroText: "",
      slices: []
    }
  },
  watch: {
    "$route.params.slug"() {
      this.getContent()
    }
  },
  created() {
    this.getContent()
  },
  methods: {
    getContent() {
      this.$prismic.client
        .getByUID("prelaunch_home", "prelaunch-home")
        .then(document => {
          this.backgroundSrc = document.data.background_image.url
          this.imageIntroText = document.data.image_intro_text
          this.slices = document.data.body
        })
    }
  }
}
</script>

<style scoped lang="scss"></style>
