<template>
  <div>
    <span v-if="prefix">{{ prefix }} {{ formattedDate(timestamp) }}</span>
  </div>
</template>

<script>
import { dateMixin } from "./../mixins/dateMixin"

export default {
  name: "PageDate",
  mixins: [dateMixin],
  props: {
    prefix: {
      type: String,
      default: null
    },
    timestamp: {
      type: String,
      default: null
    }
  }
}
</script>
