<template>
  <div>
    <template v-for="slice in renderSlices">
      <component
        :is="slice.component"
        :key="slice._uid"
        v-bind="slice.props"
      ></component>
    </template>
  </div>
</template>

<script>
import ContactForm from "./../components/ContactForm"
import FAQs from "./../components/FrequentlyAskedQuestions"
import FreeText from "./../components/FreeText"
import PageDate from "./../components/PageDate"
import PageTitle from "./../components/PageTitle"
import Profile from "./../components/UserProfile"
import Quotation from "./../components/Quote"
import SimpleTextSection from "./../components/SimpleTextSection"

import { dateMixin } from "./../mixins/dateMixin"

export default {
  name: "SliceResolver",
  components: {
    ContactForm,
    FAQs,
    FreeText,
    PageDate,
    PageTitle,
    Profile,
    Quotation,
    SimpleTextSection
  },
  mixins: [dateMixin],
  props: {
    slices: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    renderSlices() {
      let renderSlices = []

      for (let i = 0; i < this.slices.length; i++) {
        let slice = this.slices[i]

        switch (slice.slice_type) {
          case "page_title":
            renderSlices.push({
              _uid: i,
              component: PageTitle,
              props: { title: slice.primary.title }
            })
            break
          case "page_date":
            renderSlices.push({
              _uid: i,
              component: PageDate,
              props: {
                prefix: slice.primary.name,
                timestamp: slice.primary.timestamp
              }
            })
            break
          case "profile":
            renderSlices.push({
              _uid: i,
              component: Profile,
              props: {
                name: slice.primary.name,
                avatar: slice.primary.avatar.url,
                description: slice.primary.description
              }
            })
            break
          case "quotation":
            renderSlices.push({
              _uid: i,
              component: Quotation,
              props: {
                quote: slice.primary.quote,
                credit: slice.primary.credit,
                titles: slice.primary.titles
              }
            })
            break
          case "free_text":
            renderSlices.push({
              _uid: i,
              component: FreeText,
              props: { content: slice.primary.content }
            })
            break
          case "faqs":
            renderSlices.push({
              _uid: i,
              component: FAQs,
              props: { items: slice.items }
            })
            break
          case "contact_form":
            renderSlices.push({
              _uid: i,
              component: ContactForm,
              props: { settings: slice.primary, fields: slice.items }
            })
            break
          case "simple_text_section":
            renderSlices.push({
              _uid: i,
              component: SimpleTextSection,
              props: {
                title: slice.primary.title,
                content: slice.primary.content
              }
            })
            break
          default:
            break
        }
      }

      //console.log(renderSlices)

      return renderSlices
    }
  }
}
</script>

<style scoped lang="scss"></style>
