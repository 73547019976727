<template>
  <div class="quote-block">
    <div class="quote">
      <prismic-rich-text :field="quote" />
    </div>
    <div class="footer">
      <div class="credit">- {{ credit }}</div>
      <div v-if="titles">
        {{ titles }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Quotation",
  props: ["quote", "credit", "titles"]
}
</script>

<style scoped lang="scss">
@import "@/styles/ridgewolf.scss";

.quote-block {
  border-radius: 18px;
  font-size: 30px;
  background: #f9f9f9;
  border-left: 10px solid $rw-color-blue;
  margin: 0.5em 10px;
  padding: 0 10px;
  quotes: "\201C""\201D""\2018""\2019";
  padding: 10px 20px;
  line-height: 1.4;

  &:before {
    font-family: "Times New Roman", Times, serif;
    content: open-quote;
    display: inline;
    line-height: 0;
    left: -10px;
    position: relative;
    top: 30px;
    color: #ccc;
    font-size: 3em;
  }

  .footer {
    font-size: 1rem;
    margin: 0;
    text-align: right;
    font-style: italic;
  }
}
</style>
