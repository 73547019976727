<template>
  <div>
    <div v-if="!submitted">
      <form>
        <div v-if="introduction">
          <prismic-rich-text :field="introduction" />
        </div>
        <div v-for="(item, i) in fields" :key="i">
          <v-text-field
            v-if="item.type === 'Name'"
            v-model="form.name"
            :label="item.label"
            :required="item.required"
          ></v-text-field>
          <v-text-field
            v-if="item.type === 'Email'"
            v-model="form.email"
            :label="item.label"
            :required="item.required"
          ></v-text-field>
          <v-textarea
            v-if="item.type === 'Comments'"
            v-model="form.comments"
            name="input-7-1"
            :label="item.label"
            :required="item.required"
          ></v-textarea>
        </div>
        <div>
          <v-btn v-if="cancelText" color="primary" class="mr-4">{{
            cancelText
          }}</v-btn>
          <v-btn color="success" @click="submitted = true">{{
            submitText
          }}</v-btn>
        </div>
      </form>
    </div>
    <div v-if="submitted">
      <prismic-rich-text :field="submit_confirmation_text" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactForm",
  props: {
    submitText: {
      type: String,
      default: "Submit"
    },
    cancelText: {
      type: String,
      default: null
    },
    fields: {
      type: Array,
      default: () => []
    },
    introduction: {
      type: Object,
      default: () => {}
    },
    submit_confirmation_text: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      submitted: false,
      form: {
        name: "",
        email: "",
        comments: ""
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
